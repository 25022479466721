import Vue from 'vue';
import Vuex from 'vuex';
import {
  signInWithEmailAndPassword,
  signInWithEmailLink,
  sendSignInLinkToEmail,
  sendEmailVerification,
  updateEmail,
  fetchSignInMethodsForEmail,
  signOut,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  EmailAuthProvider,
  createUserWithEmailAndPassword,
  updatePhoneNumber,
  updatePassword,
  reauthenticateWithCredential,
  getAuth,
  linkWithCredential,
  sendPasswordResetEmail,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth';
import {
  getDoc,
  doc,
  updateDoc,
  setDoc,
  addDoc,
  collection,
  GeoPoint,
  orderBy,
  onSnapshot,
  serverTimestamp,
  query,
  where,
} from 'firebase/firestore';
import { nanoid } from 'nanoid';
import { httpsCallable } from 'firebase/functions';
import { auth, db, functions } from '../settings/firebaseInit';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    currentUser: {},
    currentUserData: {},
    isLoggedIn: false,
    subscriptionValidUntil: null,
    subscriptionValidUntilObject: null,
    cancelAt: null,
    subscriptionId: null,
    status: null,
    subscriptionName: null,
    selectedLanguage: 'fr',
  },
  mutations: {
    setAuth(state, { user, login }) {
      state.currentUser = user;
      state.isLoggedIn = login;
    },
    setSubscriptionDateValidUntil(state, subscription) {
      state.subscriptionValidUntil = subscription.validDate;
      state.subscriptionValidUntilObject = subscription.validDateObject;
      state.cancelAt = subscription.cancelAt;
      state.subscriptionId = subscription.id;
    },
    setUserData(state, { data }) {
      state.currentUserData = data;
    },
    setSubscriptionStatus(state, status) {
      state.status = status;
    },
    setSubscriptionName(state, subsName) {
      state.subscriptionName = subsName;
    },
    setSelectedLanguage(state, newValue) {
      state.selectedLanguage = newValue;
    },
  },
  actions: {
    async login({ state }, { email, password }) {
      try {
        const login = await signInWithEmailAndPassword(auth, email, password);
        state.currentUser = login.user;
        return { success: true, message: login };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async logout() {
      try {
        await signOut(auth);
        return { success: true, message: '' };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async checkEmail(_, email) {
      // checks if email has password signin method
      try {
        const checkEmail = await fetchSignInMethodsForEmail(auth, email);
        return checkEmail.includes('password');
      } catch (error) {
        return true;
      }
    },
    async verifyEmail(_, { email, settings }) {
      // verifies the email before registration
      try {
        const res = await sendSignInLinkToEmail(auth, email, settings);
        return { success: true, message: res };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async verifyEmailLink(_, { email, link }) {
      try {
        // makes sure that link is valid
        const res = await signInWithEmailLink(auth, email, link);
        return { success: true, message: res };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async reauthenticateEmailLink(_, { email, link }) {
      try {
        const credential = EmailAuthProvider.credentialWithLink(email, link);
        const emailCredential = await reauthenticateWithCredential(auth.currentUser, credential);
        return { success: true, message: emailCredential };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async updateAndVerifyEmail(_, { email, settings }) {
      // updates email and sends a verification link
      try {
        await updateEmail(auth.currentUser, email);
        const res = await sendEmailVerification(auth.currentUser, settings);
        return { success: true, message: res };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async sendEmailVerification(_, settings) {
      try {
        await sendEmailVerification(auth.currentUser, settings);
        return { success: true, message: 'success.verification-email-msg' };
      } catch (e) {
        return { success: false, message: e };
      }
    },
    async recaptchaVerifier() {
      try {
        if (!window.recaptchaVerifier) {
          window.recaptchaVerifier = new RecaptchaVerifier(
            'recaptcha-container',
            {
              size: 'invisible',
              // eslint-disable-next-line no-unused-vars
              callback: (response) => {},
            },
            // eslint-disable-next-line comma-dangle
            auth,
          );
        }
        await window.recaptchaVerifier.render();
      } catch (error) {
        console.log('Error: ', error);
      }
    },
    async sendSMS({ dispatch }, phoneNumber) {
      await dispatch('recaptchaVerifier');
      const appVerifier = window.recaptchaVerifier;
      try {
        const confirmationResult = await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
        window.confirmationResult = confirmationResult;
        return { success: true, message: confirmationResult };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async verifyOTP(_, code) {
      const { confirmationResult } = window;

      if (confirmationResult !== undefined) {
        try {
          const otp = await PhoneAuthProvider.credential(confirmationResult.verificationId, code);
          return { success: true, message: otp };
        } catch (error) {
          return { success: false, message: error };
        }
      }

      return { success: false, message: 'error.code-missing' };
    },
    async updatePhoneNumber(_, { verificationId, verificationCode }) {
      try {
        const res = await updatePhoneNumber(
          auth.currentUser,
          PhoneAuthProvider.credential(verificationId, verificationCode),
        );
        return { success: true, message: res };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async registerUser(_, { email, password }) {
      try {
        const res = await createUserWithEmailAndPassword(auth, email, password);
        return { success: true, message: res };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async linkEmailPassword(_, { email, password }) {
      try {
        const credential = EmailAuthProvider.credential(email, password);
        const linkCredential = await linkWithCredential(auth.currentUser, credential);
        return { success: true, message: linkCredential };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async updateUserPassword(_, newPassword) {
      try {
        const res = await updatePassword(auth.currentUser, newPassword);
        return { success: true, message: { ...res, user: auth.currentUser } };
      } catch (error) {
        let message = 'error.something-went-wrong';
        if (error.code === 'auth/requires-recent-login') {
          message = 'error.requires-login';
        }
        return { success: false, message };
      }
    },
    async sendPaswordResetEmail(_, { email, settings }) {
      try {
        const authData = getAuth();
        const res = await sendPasswordResetEmail(authData, email, settings);
        return { success: true, message: res };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async resetPassword(_, { code, newPassword }) {
      try {
        const authData = getAuth();
        const res = await confirmPasswordReset(authData, code, newPassword);
        return { success: true, message: res };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async verifyResetCode(_, code) {
      try {
        const authData = getAuth();
        const res = await verifyPasswordResetCode(authData, code);
        return { success: true, message: res };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async getDefaultOrg() {
      try {
        const configs = await getDoc(doc(db, 'configurations', 'globals'));
        const configData = configs.data();
        const defaultOrg = await getDoc(doc(db, 'organizations', configData.defaultOrganizationId));
        return defaultOrg.ref;
      } catch (error) {
        return null;
      }
    },
    async setUserData({ dispatch }, { id, userData }) {
      try {
        const defaultOrg = await dispatch('getDefaultOrg');

        const updateDataRef = doc(db, 'users', id);
        await setDoc(updateDataRef, {
          ...userData,
          organization: defaultOrg,
        }, { merge: true });

        const devicesDb = collection(db, 'devices');
        await addDoc(devicesDb, {
          deviceName: `${userData.firstName} ${userData.lastName}`,
          deviceType: 'Mobile Phone',
          newDevice: true,
          isDefault: true,
          position: new GeoPoint(0.0, 0.0),
          owner: updateDataRef,
          password: nanoid(16),
          username: nanoid(16),
        });

        return { success: true, message: '' };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    // Subscription
    getPreviousSubscriptions({ commit }) {
      const res = query(collection(db, 'users', auth.currentUser.uid, 'subscriptions'), orderBy('created', 'desc'));
      onSnapshot(res, (querySnapshot) => {
        querySnapshot.docs.map((session) => {
          const snapshot = session.data();
          commit('setSubscriptionStatus', snapshot.status);
          return snapshot;
        });
      });
    },
    async setUserSubscription() {
      const updateDataRef = doc(db, 'users', auth.currentUser.uid);
      const subscriptionData = {
        needSubscription: true,
        subsciptionExpireAfter: serverTimestamp(),
      };
      try {
        await updateDoc(updateDataRef, subscriptionData);
      } catch (error) {
        console.log('setUserSubscripion ERROR: ', error);
      }
    },
    async cancelUserSubscription() {
      try {
        const cancelSub = httpsCallable(functions, 'userCancelSubscriptionByUserId');
        const result = await cancelSub({ uid: auth.currentUser.uid });
        return result.data;
      } catch (error) {
        return 'error';
      }
    },
    async renewSubscription({ state, dispatch }) {
      try {
        const renewSub = httpsCallable(functions, 'userRenewSubscription');
        const result = await renewSub({ subscriptionId: state.subscriptionId });
        dispatch('getUsersSubscriptions');
        return result.data;
      } catch (error) {
        return error;
      }
    },
    async getUsersSubscriptions({ commit, dispatch }) {
      try {
        const subscriptions = httpsCallable(functions, 'userListSubscriptions');
        const result = await subscriptions({ uid: auth.currentUser.uid });
        let subscriptionData = {
          validDate: null,
          cancelAt: null,
          validDateObject: null,
          id: null,
        };
        if (result.data.length) {
          const subs = result.data
            .sort((a, b) => new Date(b.current_period_end * 1000).toLocaleDateString()
          - new Date(a.current_period_end * 1000).toLocaleDateString());
          dispatch('getUserSubscriptionName');
          const validDate = `${new Date(subs[0].current_period_end * 1000).toLocaleDateString()} ${new Date(subs[0].current_period_end * 1000).toLocaleTimeString()}`;
          const validDateObject = new Date(subs[0].current_period_end * 1000);
          const cancelAt = subs[0].cancel_at;
          subscriptionData = {
            validDate,
            cancelAt,
            validDateObject,
            id: subs[0].id,
          };
          commit('setSubscriptionDateValidUntil', subscriptionData);
        }
        commit('setSubscriptionDateValidUntil', subscriptionData);
      } catch (e) {
        console.error(e);
      }
    },
    async getUserSubscriptionName({ commit }) {
      const res = query(collection(db, 'users', auth.currentUser.uid, 'subscriptions'), where('status', '==', 'active'));
      onSnapshot(res, (querySnapshot) => {
        querySnapshot.docs.map((session) => {
          const snapshot = session.data();
          commit('setSubscriptionName', snapshot.items[0].price.product.name);
          return session;
        });
      });
    },
    async getUserData({ state, commit }) {
      try {
        const userRef = doc(db, 'users', state.currentUser.uid);
        const user = await getDoc(userRef);
        const data = user.data();

        commit('setUserData', { data });

        return { success: true, message: '' };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async deleteAccount({ state }) {
      try {
        const deleteUserAccount = httpsCallable(functions, 'deleteUserData');
        const result = await deleteUserAccount({ uid: state.currentUser.uid });
        return { success: true, message: result.data };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async updateUserData({ state, commit }, { id, userData, userCredentials }) {
      try {
        if (userCredentials) {
          await signInWithEmailAndPassword(auth, userCredentials.email, userCredentials.password);
        }

        const updateDataRef = doc(db, 'users', id);
        await updateDoc(updateDataRef, {
          ...userData,
        });

        const data = { ...state.currentUserData, ...userData };
        commit('setUserData', { data });

        return { success: true, message: '' };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async updateStripeData(_, payload) {
      try {
        const updateData = httpsCallable(functions, 'userUpdateData');
        await updateData(payload);
        return { success: true, message: '' };
      } catch (error) {
        return { success: false, message: error };
      }
    },
    async setEmailAsVerified() {
      try {
        const verifyEmailSub = httpsCallable(functions, 'userSetEmailAsVerified');
        const result = await verifyEmailSub({ uid: auth.currentUser.uid });
        return result.data;
      } catch (error) {
        return 'error';
      }
    },
  },
});
