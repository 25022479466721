import Vue from 'vue';
import VueI18n from 'vue-i18n';
import { languages, defaultLocale } from './i18n/index';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import auth from './settings/auth';

Vue.use(VueI18n);

const messages = Object.assign(languages);
const i18n = new VueI18n({
  locale: defaultLocale,
  fallbackLocale: 'en',
  messages,
});

Vue.config.productionTip = false;

auth(router);

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');
