import { onAuthStateChanged } from 'firebase/auth';
import store from '../store';

import { auth } from './firebaseInit';

const getCurrentUser = () => new Promise((resolve, reject) => {
  const removeListener = onAuthStateChanged(
    auth,
    (user) => {
      removeListener();
      resolve(user);
    },
    reject,
  );
});

export default (router) => {
  router.beforeEach(async (to, from, next) => {
    const user = await getCurrentUser();
    store.commit('setAuth', { user, login: !!user });

    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.state.isLoggedIn) {
        next({ name: 'Login' });
      } else {
        next();
      }
    } else if (to.matched.some((record) => record.meta.requiresVisitor)) {
      if (!store.state.isLoggedIn) {
        next();
      } else {
        next({ name: 'Dashboard' });
      }
    } else {
      next();
    }
  });
};
