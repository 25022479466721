<template>
  <v-app id="app">
    <v-snackbar
      v-model="alertMessage.show"
      :color="alertMessage.type"
      :timeout="5000"
      :top="'top'"
    >
      {{ $t(alertMessage.message) }}
      <template v-slot:action="{ attrs }">
        <v-btn icon dark text v-bind="attrs" @click="alertMessage.show = false">
          <v-icon>{{ closeIcon }}</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <v-main
      id="mainApp"
      class="primary text-center">
      <v-row no-gutters>
        <v-col cols="12" xl="1">
          <v-btn class="accent1--text r-link" plain @click="navigateToHome">
            {{ $t('buttons.home') }}
          </v-btn>
        </v-col>
        <v-col cols="12" xl="2">
          <v-btn class="accent1--text r-link" plain>
            <a :href="`mailto:${supportEmail}`">{{ $t('labels.contact-support') }}</a>
          </v-btn>
          <v-icon @click="copyEmail" color="accent1">
            {{ copyIcon }}
          </v-icon>
        </v-col>
        <v-col lg="8"></v-col>
        <v-col cols="12" xl="1">
          <v-btn class="accent1--text r-link" plain @click="toggleLang">
            {{ $t('change-lang') }}
          </v-btn>
        </v-col>
      </v-row>
      <router-view id="routerView" class="fill-height" />
      <!-- To retain the rendered recapcha even page changes  -->
      <div id="recaptcha-container"></div>
    </v-main>
  </v-app>
</template>

<script>
import { mdiContentCopy, mdiClose } from '@mdi/js';
import { mapState, mapMutations } from 'vuex';

export default {
  name: 'App',
  data() {
    this.supportEmail = process.env.VUE_APP_SUPPORT_EMAIL;
    return {
      alertMessage: { show: false, type: '', message: '' },
      copyIcon: mdiContentCopy,
      closeIcon: mdiClose,
    };
  },
  computed: {
    ...mapState(['selectedLanguage']),
  },
  methods: {
    ...mapMutations(['setSelectedLanguage']),
    toggleLang() {
      this.$i18n.locale = this.$i18n.locale === 'fr' ? 'en' : 'fr';
      this.setSelectedLanguage(this.$i18n.locale);
    },
    navigateToHome() {
      this.$router.push({ name: 'Dashboard' });
    },
    async copyEmail() {
      try {
        await navigator.clipboard.writeText(this.supportEmail);
        this.alertMessage = { show: true, type: 'success', message: 'labels.email-copied' };
      } catch (e) {
        console.error(e);
        this.alertMessage = { show: true, type: 'error', message: 'labels.cannot-copy' };
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/assets/styles/global.scss';

#app {
  font-family: Noto Sans, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}

#mainApp {
  padding-bottom: 3em !important;
}

#language {
  position: absolute;
  top: 10px;
  right: 20px;
  cursor: pointer;
}

.home {
  display: flex;
  flex-direction: row;
  align-content: start;
  padding-left: 20px;
}

a {
  text-decoration: none;
}

.v-icon__svg {
  height: 18px !important;
}
</style>
