import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: false,
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: '#2b2929',
        secondary: '#E0E0E0',
        accent: '#e1cdb4',
        accent1: '#ab835e',
        error: '#d03a3a',
        anchor: '#ab835e',
      },
      light: {
        primary: '#2b2929',
        secondary: '#E0E0E0',
        accent: '#e1cdb4',
        accent1: '#ab835e',
        error: '#d03a3a',
        anchor: '#ab835e',
      },
    },
  },
  icons: {
    iconfont: 'mdiSvg',
  },
});
