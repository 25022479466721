import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const routes = [
  {
    path: '/auth/action',
    name: 'AuthAction',
    component: () => import('@/views/AuthAction.vue'),
  },
  {
    path: '/download-app',
    name: 'DownloadApp',
    component: () => import('@/views/DownloadApp.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginView.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/views/RegisterView.vue'),
  },
  {
    path: '/forgot-password',
    name: 'ForgotPassword',
    component: () => import('@/views/ForgotPassword.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/reset-password',
    name: 'ResetPasswordView',
    component: () => import('@/views/ResetPasswordView.vue'),
  },
  {
    path: '/update-email',
    name: 'EmailVerifySuccess',
    component: () => import('@/views/Update/EmailVerifySuccess.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/views/DashboardView.vue'),
    alias: ['/dashboard', '/home', '/verify-email'],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/payment-success',
    name: 'SuccessPayment',
    component: () => import('@/views/Subscription/SuccessView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/verify-phone',
    name: 'VerifyPhoneView',
    component: () => import('@/views/VerifyPhoneView.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/NotFound.vue'),
    meta: {
      requiresVisitor: true,
    },
  },
  {
    path: '/terms-and-conditions',
    name: 'TermsAndConditions',
    component: () => import('@/views/TermsAndConditions.vue'),
  },
  {
    path: '/subscriptions/:uid/:email',
    name: 'SubscriptionsView',
    component: () => import('@/views/Subscription/Subscriptions.vue'),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '*',
    redirect: '404',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
